html {
    font-weight: 300;
    font-size: 16px;
	line-height: 28px;
	font-family: var(--font-main);
	color: var(--white);

	scrollbar-width: thin;
	scrollbar-color: transparent var(--white);

	&::-webkit-scrollbar {
		width: 8px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--white);
	}

}

body {
    min-width: 320px;
	margin: 0;
	background-color: var(--black);
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.swiper {
    width: 100% !important;
    overflow: visible !important;
}

.swiper-wrapper {
	left: 0 !important;
}

img {
    width: 100%;
	height: 100%;
	object-fit: cover;
	transition: opacity 0.1s ease;
	
	&.lazyload {
	   opacity: 0;
	}
 
	&.lazyloaded {
	   opacity: 1;
	}
}

#site {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.container {
	width: 100%;
	max-width: 1320px;
	margin: 0 auto;
	padding: 0 20px;
}

#bold-credits {
	width: 150px;
	transition: ease-in-out 0.25s;

	&::before,
	&::after {
		display: none;
	}

	&:hover {
		transform: scale(1.1);
	}
}

.arrow {
	display: inline-flex;
	width: 10px;
	height: 10px;
	background-position: left bottom;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
	transition: .3s ease;
}

.btn { 
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	padding: 0 25px;
	min-width: 212px;
	border-radius: 55px;
	border: 1px solid var(--grey);
	
	&, .text {
		transition: .3s ease;
	}

	.text {
		line-height: 48px;
		font-size: 14px;
		font-weight: 500;
		margin-right: 15px;
	}

	&:hover {
		background-color: var(--white);

		.text {
			color: var(--black);
		}

		.arrow {
			transform: rotate(-45deg);
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
		}
	}
}

.page-pagination {
	position: fixed;
	right: 14px;
	top: calc(50% + 11px);
	transform: translateY(-50%) !important;
	display: flex;
	flex-direction: column;
	z-index: 8;

	a {
		margin: 10px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 24px;
		height: 24px;
		transition: .3s ease;
		opacity: 1;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			width: 100%;
			height: 100%;
			border-radius: 100%;
			border: 1px solid var(--white);
			transition: .3s ease;
		}

		&::after {
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: var(--white);
			transition: .3s ease;
		}

		&.active, &:hover {
			&::before {
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
}

.page404 {
	position: relative;

	@keyframes gradient {
		0% {
			background-position: 0% 50%
		}

		50% {
			background-position: 100% 50%
		}

		100% {
			background-position: 0% 50%
		}
	}

	&__wrap {
		position: relative;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		z-index: 2;
	}

	h1 {
		margin-bottom: 0.3em;
	}

	p {
		font-size: ac(22px, 18px);
		line-height: 22px;
		margin-bottom: 2em;
	}

	.btn {
		.text {
			color: var(--white);
		}

		&:hover {
			.text {
				color: var(--black);
			}
		}
	}
}

.terms {
	margin-top: ac(220px, 150px);
	padding-bottom: 50px;

	&__wrapper {
		a {
			transition: .3s ease;
			display: inline-block;
			color: transparent;
			background: -moz-linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
			background: -webkit-linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
			background: linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
			background-clip: text;
			-webkit-background-clip: text;
			word-break: break-word;

			&:hover {
				color: var(--smokey);
			}
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			&:not(:last-child) {
				margin-bottom: ac(21px, 15px);
			}

			&:not(:first-child) {
				margin-top: ac(39px, 20px);
			}
		}
		h1 {
			margin-bottom: ac(50px, 25px);
		}

		h3,
		h4,
		h5,
		h6 {
			font-weight: 500;
		}

		h3 {
			font-size: ac(40px, 28px);
			line-height: ac(46px, 38px);
		}

		h4 {
			font-size: ac(30px, 26px);
			line-height: ac(40px, 36px);
		}

		h5 {
			font-size: ac(28px, 24px);
			line-height: ac(38px, 34px);
		}

		h6 {
			font-size: ac(25px, 22px);
			line-height: ac(30px, 28px);
		}

		p, li {
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
		}

		p, ul, ol {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		ul, ol {
			padding-left: 0;
			list-style: none;

			li {
				position: relative;
				padding-left: 24px;
				margin-bottom: 10px;
			}
		}

		ul {
			li {
				&::before {
					content: '';
					position: absolute;
					left: 10px;
					top: 10px;
					width: 3px;
					height: 3px;
					border-radius: 100%;
					background-color: var(--white);
				}
			}
		}

		ol {
			list-style: none;
			counter-reset: my-counter;

			li {
				counter-increment: my-counter;

				&::before {
					content: counter(my-counter) ". ";
					position: absolute;
					left: 0px;
					color: var(--white);
					font-weight: 500;
				}
			}
		}
	}
}

.red {
	.hero--sector {
		.hero__gradient {
			img {
				opacity: .4;

				&:nth-child(1), &:nth-child(3), &:nth-child(4) {
					display: none !important;
				}

				&:nth-child(2) {
					display: block !important;
				}
			}
		}
	}

	.team__img {
		&::before {
			background: url('../images/team-gradient-red.svg') center / contain no-repeat;
		}
	}

	.testimonials__decor {
		img {
			opacity: .4;

			&:nth-child(1), &:nth-child(3), &:nth-child(4) {
				display: none !important;
			}

			&:nth-child(2) {
				display: block !important;
			}
		}
	}
}

.blue {
	.hero--sector {
		.hero__gradient {
			img {
				&:nth-child(1), &:nth-child(2), &:nth-child(4) {
					display: none !important;
				}

				&:nth-child(3) {
					display: block !important;
				}
			}
		}
	}

	.team__img {
		&::before {
			background: url('../images/team-gradient-blue.svg') center / contain no-repeat;
		}
	}

	.testimonials__decor {
		img {
			&:nth-child(1), &:nth-child(2), &:nth-child(4) {
				display: none !important;
			}

			&:nth-child(3) {
				display: block !important;
			}
		}
	}
}

.yellow {
	.hero--sector {
		.hero__gradient {
			img {
				opacity: .4;
				&:nth-child(1), &:nth-child(2), &:nth-child(3) {
					display: none !important;
				}

				&:nth-child(4) {
					display: block !important;
				}
			}
		}
	}

	.team__img {
		&::before {
			background: url('../images/team-gradient-yellow.svg') center / contain no-repeat;
		}
	}

	.testimonials__decor {
		img {
			opacity: .4;
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				display: none !important;
			}

			&:nth-child(4) {
				display: block !important;
			}
		}
	}
}

/* Cookies popup */
.termsfeed-com---pc-dialog {
	border: 1px solid var(--dune);

	.cc-cp-body-tabs {
		background: var(--white) !important;
	}

	.cc-pc-head-close {
		color: var(--white) !important;
	}

	.cc-pc-head-close {
		border: 0 !important;

		&:hover {
			color: var(--grey) !important;
		}
	}

	.cc-cp-body-tabs-item {
		background: var(--onyx) !important;
		user-select: none;
		transition: .3s ease;

		&:hover {
			background: var(--grey);
		}

		&::before {
			display: none !important;
		}

		button {
			color: var(--white) !important;
			transition: .3s ease;
		}
	}

	.cc-cp-body-tabs-item[active=true] {
		background: var(--white) !important;

		button {
			color: var(--black) !important;
			box-shadow: unset !important;
		}
	}

	.cc-cp-foot, .cc-pc-head {
		background: var(--onyx) !important;
	}

	.cc-cp-body-content {
		background: var(--white) !important;
	}

	.cc-pc-head-title-text, .cc-cp-foot-byline, .cc-pc-head-title-headline {
		color: var(--white) !important;

		a {
			display: inline;
			color: var(--white) !important;
			text-decoration: underline;
			transition: .3s ease;

			&:hover {
				color: var(--grey) !important;
			}
		}
	}

	.cc-pc-head-title-headline, .cc-cp-foot-save, .cc-cp-foot-byline, .cc-cp-body-tabs-item-link, .cc-cp-body-content-entry > *, .cc-custom-checkbox>label {
		font-family: var(--font-main) !important;
	}

	.cc-cp-body-content-entry-title, .cc-custom-checkbox label {
		color: var(--black) !important;
	}

	.is-inactive, .cc-cp-body-content-entry-text {
		color: var(--black) !important;

		a {
			text-decoration: underline;
			color: var(--black) !important;
			transition: .3s ease;

			&:hover {
				text-decoration: underline;
				color: var(--grey) !important;
			}
		}
	}

	.cc-cp-foot-save {
		display: inline-flex;
		justify-content: center;
		align-items: center;

		padding: 0 20px !important;
		margin-bottom: 0 !important;

		border: 1px solid var(--grey) !important;
		border-radius: 55px !important;
		background-color: transparent !important;

		font-size: 14px;
		font-weight: 500;
		line-height: 38px !important;
		color: var(--white) !important;
		text-transform: uppercase;

		transition: .3s ease;

		&::after {
			content: '';
			margin-left: ac(15px, 5px);
			width: 10px;
			height: 10px;
			background-position: left bottom;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
			transition: .3s ease;
		}

		&:hover {
			color: var(--black) !important;
			background-color: var(--white) !important;

			&::after {
				transform: rotate(-45deg);
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
			}
		}
	}

	@media only screen and (max-width: 551px) {
		.cc-cp-foot {
			flex-direction: column;
			padding-bottom: 20px
		}
	}
}

/* agree popup */
.termsfeed-com---palette-dark.termsfeed-com---nb {
	background: var(--onyx) !important;
	border: 1px solid var(--dune);
	border-radius: 8px 0 0 0;

	.cc-nb-main-container {
		padding: ac(45px, 25px) ac(60px, 25px) ac(30px, 25px) !important
	}

	.cc-nb-title, .cc-nb-text {
		color: var(--white) !important;
	}

	.cc-nb-text {
		margin-bottom: 0;
	}

	.cc-nb-buttons-container {
		button {
			display: inline-flex;
			justify-content: center;
			align-items: center;

			padding: 0 20px !important;
			margin-bottom: 0 !important;
			margin-top: 13px !important;

			border: 1px solid var(--grey) !important;
			border-radius: 55px !important;
			background-color: transparent !important;

			font-size: 14px;
			font-weight: 500;
			line-height: 38px !important;
			color: var(--white) !important;
			text-transform: uppercase;

			transition: .3s ease;

			&::after {
				content: '';
				margin-left: ac(15px, 5px);
				width: 10px;
				height: 10px;
				background-position: left bottom;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
				transition: .3s ease;
			}

			&:hover {
				color: var(--black) !important;
				background-color: var(--white) !important;

				&::after {
					transform: rotate(-45deg);
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
				}
			}

			&.cc-nb-changep {
				border: 1px solid var(--grey) !important;
				background-color: var(--white) !important;
				color: var(--black) !important;

				&::after {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
				}

				&:hover {
					background-color: transparent !important;
					color: var(--white) !important;

					&::after {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 428px) {
	.termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
		justify-content: space-between !important;
	}
}
/* end agree popup */

@mixin tab {
	.page-pagination {
		display: none;
	}
	
	.btn {
		min-width: 162px;
		background-color: var(--black);

		.text {
			line-height: 38px;
			font-size: 12px;
			font-weight: 400;
			margin-right: 17px;
		}

		.arrow {
			width: 7px;
			height: 7px;
		}

	}
}

