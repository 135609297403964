.footer {
    margin-top: 50px;
    padding-bottom: 50px;
    background-color: var(--black);
    z-index: 3;

    &--contact {
        border-top: 1px solid var(--dune);
        margin-top: ac(157px, 85px);

        .footer__contact-btn {
            display: none;
        }

        .footer__wrapper {
            border: 0;
            padding-top: ac(58px, 50px);
        }
    }

    &--sector {
        border-top: 1px solid var(--dune);
        
        .footer__wrapper {
            border: 0;
        }
    }
    
    &--about {
        border-top: 1px solid var(--dune);

        .footer__wrapper {
            border: 0;
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: ac(58px, 108px);
        border-top: 1px solid var(--dune);
    }

    &__contact-btn {
        position: absolute;
        right: ac(147px, 40px);
        top: ac(-75px, -50px);
        
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
     
        width: ac(150px, 100px);
        height: ac(150px, 100px);
        border-radius: 100%;
        border: 1px solid var(--davy);
        background-color: var(--black);
        transition: .3s ease;

        .text {
            margin-top: -4px;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: ac(14px, 12px);
            font-weight: 500;
            transition: .3s ease;
        }

        .arrow {
            width: 21px;
            height: 21px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.707' height='21.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M8.707 20.707h13M20.707 9.707v12M.707.707l20 20'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:hover {
            background-color: var(--white);

            .text {
                color: var(--black);
            }

            .arrow {
                transform: rotate(-45deg);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.707' height='21.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M8.707 20.707h13M20.707 9.707v12M.707.707l20 20'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &__title {
        font-size: 18px;
        line-height: 28px;
    }

    &__mail {
        margin-top: ac(8px, 5px);
        font-size: ac(50px, 24px);
        line-height: ac(62px, 34px);
        font-weight: 500;
    }

    &__contact-box {
        display: flex;
        margin-top: ac(56px, 28px);
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: ac(25px, 27px);
        margin-right: ac(160px, 100px, 1024, 1440);

        &--menu {
            margin-right: 0;

            .footer__city {
                margin-bottom: 10px;
            }
        }
    }

    &__logo {
        flex: 0 0 auto;
        position: relative;
        margin-top: 32px;
        margin-right: ac(179px, 75px, 1024, 1440);
        width: ac(145px, 64px);
        height: ac(145px, 64px);

        &>img {
            object-fit: contain;
            transition: .3s ease;

            &:nth-child(2) {
                position: absolute;
                top: 0;
                opacity: 0;
            }

            &:nth-child(3) {
                display: none;
            }
        }

        &:hover {
            .footer__logo-gradient {
                transform: rotate(-90deg) scale(1.1);
            }

            &>img {
                &:nth-child(1) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }
    }

    &__logo-gradient {
        position: absolute;
        top: ac(-31px, -13px, 768, 1440);
        left: ac(-31px, -13px, 768, 1440);
        width: ac(208px, 90px, 768, 1440);
        height: ac(208px, 90px, 768, 1440);
        z-index: -1;
        transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &>img {
            object-fit: contain;
            transition: .3s ease;
        }
    }

    &__city {
        font-size: 14px;
        color: var(--smokey);
    }

    &__address, &__tel, &__link, &__mail {
        transition: .3s ease;
        background: -moz-linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
        background: linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;

        &:hover {
            color: rgba(0, 0, 0, 0);
        }
    }

    &__address {
        margin-top: ac(11px, 14px);
        letter-spacing: .025em;
    }
    
    &__tel {
        letter-spacing: .025em;
        margin-top: ac(22px, 18px);
    }

    &__link {
        letter-spacing: .025em;
    }

    &__copy-box {
        width: 100%;
        margin-top: ac(92px, 28px);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__copy {
        margin: 0 ac(25px, 20px);
        font-size: 14px;
        color: var(--smokey);
        transition: .3s ease;
    }

    a.footer__copy {
        &:hover {
            color: var(--white);
        }
    }

    @mixin tab {
        &__mail {
            font-weight: 400;
        }

        &__address, &__tel,  &__link {
            letter-spacing: 0;
            line-height: 26px;
        }

        &__link {
            line-height: 30px;
        }

        &__contact-btn {
            .text {
                font-weight: 400;
                margin-bottom: 0;
                margin-top: -5px;
            }

            .arrow {
                width: 19px;
                height: 19px;
            }
        }

        &__contact-box {
            flex-wrap: wrap;
        }

        &__logo {
            margin-right: 100%;
        }

        &__col {
            margin-right: ac(230px, 50px, 658, 1024);
        }

        &__col--menu {
            margin-right: 0;
        }

        &__copy {
            line-height: 34px;
        }
    }

    @mixin mob-xl {
        &__contact-box {
            flex-flow: nowrap column;
        }

        &__logo {
            img {
                &:nth-child(1),
                &:nth-child(2) {
                    display: none;
                }

                &:nth-child(3) {
                    display: block;
                }
            }
        }
    }
}