.about-us {
    position: relative;
    z-index: 2;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		padding: 0 ac(108px, 0px, 768, 1440);
	}

	&__col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
		width: calc(50% - 10px);

        &:nth-child(2) {
            margin-top: ac(30px, 0px);
        }

		& > *:first-child {
            display: flex;
			min-height: 126px;
			margin-bottom: ac(52px, 20px);
		}

        &>p:first-child {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0.2px;
            font-weight: 300;
            padding-bottom: 5px;
        }

        p {
            letter-spacing: .2px;
            &:not(:last-child) {
                margin-bottom: ac(10px, 20px);
            }
        }
	}

    &__btn {
        margin-top: auto;
        transform: translateY(ac(32px, 0px));
    }

    @mixin tab {
        &__wrapper {
            flex-direction: column;
        }

        &__col {
            width: 100%;

            &:nth-child(2) {
                margin-top: 0px;
            }

            &:first-child {
                margin-bottom: ac(52px, 20px);
            }

            & > *:first-child {
                min-height: unset;
            }

            &>p:first-child {
                padding-bottom: 0px;
            }
        }

        &__btn {
            transform: translateY(0);
        }
    }
}
