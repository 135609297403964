h1 {
    font-size: ac(80px, 40px);
    line-height: ac(94px, 54px);
    font-weight: 400;
    letter-spacing: 1px;
}

h2 {
    font-size: ac(50px, 30px);
    line-height: ac(62px, 42px);
    font-weight: 500;
}

@mixin tab {
    h1 {
        font-weight: 400;
    }
}

@mixin mob-sm {
    h1 {
        font-size: 32px;
        line-height: 44px;
    }
}