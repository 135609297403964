.hero {
    position: relative;
    z-index: 2;

    &__cubes {
        height: 100%;
        left: 80px !important;
        position: absolute;
        top: -50px !important;
        width: 100%;

        img {
            object-fit: contain;
            object-position: right top;
        }
    }

    &__dots , &__gradient, &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0 !important;
        left: 0 !important;

        img {
            object-fit: contain;
            object-position: right top;
        }
    }

    &__gradient {
        left: 100px !important;
        top: -100px !important;

        img {
            opacity: .5;
        }
    }

    &__dots {
        opacity: .2;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 119px;
        padding: ac(202px, 15px, 320, 1440) 0 ac(189px, 170px, 320, 1440);
    }

    &__title {
        display: flex;
        flex-direction: column;

        span {
            &:nth-child(2) {
                margin-left: ac(103px, 50px);
            }
        }
    }

    &__descr {
        margin-top: ac(35px, 17px);
        letter-spacing: .025em;
    }

    &__btn {
        margin-top: ac(46px, 30px);
    }

    &__contact-btn {
        position: absolute;
        left: ac(160px, 37px);
        bottom: ac(90px, 129px);
        
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
     
        width: ac(150px, 100px);
        height: ac(150px, 100px);
        border-radius: 100%;
        border: 1px solid var(--davy);
        transition: .3s ease;

        .text {
            margin-top: -4px;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: ac(14px, 12px);
            font-weight: 500;
            transition: .3s ease;
        }

        .arrow {
            width: 21px;
            height: 21px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.707' height='21.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M8.707 20.707h13M20.707 9.707v12M.707.707l20 20'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:hover {
            background-color: var(--white);

            .text {
                color: var(--black);
            }

            .arrow {
                transform: rotate(-45deg);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.707' height='21.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M8.707 20.707h13M20.707 9.707v12M.707.707l20 20'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &__scroll-down {
        position: absolute;
        right: 20px;
        bottom: ac(90px, 40px);
        user-select: none;
        cursor: pointer;
        transition: .3s ease;

        .arrow {
            margin-right: ac(6px, 10px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23717171' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
        }

        .text {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 14px;
            color: var(--smokey);
            transition: .3s ease;
        }

        &:hover {
            .arrow {
                transform: rotate(45deg);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
            }

            .text {
                color: var(--white);
            }
        }
    }

    &--about,
    &--sector {
        .hero__wrapper {
            padding: ac(202px, 15px, 320, 1440) 0 ac(189px, 100px, 320, 1440);
        }

        .hero__descr {
            margin-top: ac(30px, 17px);
            letter-spacing: .01em;
        }

        .hero__gradient {
            height: 128%;
            left: ac(100px, 50px) !important;
            top: ac(-100px, -50px) !important;
        }

        .hero__dots {
            opacity: 1;
            height: 110%;
            top: 134px !important;
        }
    }

    &--sector {
        .hero__gradient {
            img {
                &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    display: none !important;
                }
            }
        }
    }

    &--about {
        .hero__wrapper {
            padding: ac(202px, 15px, 320, 1440) 0 ac(144px, 100px, 320, 1440);
        }

        .hero__gradient {
            height: 200%;
            left: ac(250px, 100px) !important;
            top: ac(-500px, -100px) !important;
        }
    }

    &--contact {
        padding: 127px 0 0;

        .container {
            height: auto;
        }

        .hero__wrapper {
            padding: 0;
        }

        .hero__gradient {
            width: ac(700px, 500px);
            height: ac(700px, 500px);
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%,calc(-50% + 189px));
            z-index: -1;
        }

        .hero__dots {
            width: ac(1100px, 800px);
            height: ac(1100px, 800px);
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%,calc(-50% + 188px));
            opacity: 1;
            z-index: -1;
        }

        .hero__descr {
            margin-top: ac(30px, 17px);
            letter-spacing: .01em;
        }
    }

    @media (min-width: 2400px) {
        &--contact, &--sector, &--about {
            margin-top: 120px;
        }
        
        &__wrapper {
            margin-top: 0;
        }

        &__descr {
            margin-top: 50px;
        }

        &__btn {
            margin-top: 50px;
        }
    }

    @mixin tab {
        .container {
            align-items: flex-start;
        }

        &__wrapper {
            margin-top: 22vh;
        }

        &__descr {
            letter-spacing: 0.01em;
        }

        &__contact-btn {
            bottom: 40px;

            .text {
                font-weight: 400;
                margin-bottom: 0;
                margin-top: -5px;
            }

            .arrow {
                width: 19px;
                height: 19px;
            }
        }

        &--contact {
            padding-top: 0;
        }
    }
    
    @media (max-height: 800px) and (min-width: 1024px) {
        &__contact-btn {
            opacity: 1 !important;
            transform: none !important;
        }
    }

    @media (max-height: 670px) {
        &__wrapper {
            margin-top: 20vh;
        }

        &__contact-btn {
            opacity: 1 !important;
            transform: none !important;
        }
    }

    @media (max-height: 560px) {
        &__wrapper {
            margin-top: 16vh;
        }
    }
}