.say {
    &__wrapper {
        position: relative;
        padding-top: 185px;
    }

    &__bg {
        position: absolute;
        right: -11%;
        top: 55px;
        width: 66%;
        height: 100%;
        max-height: ac(600px, 500px);
        z-index: -1;

        img {
            object-position: 0px ac(-100px, -60px, 1024, 1440);
            box-shadow: inset 0 0 50px 50px var(--black);
        }

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &::before {
            background: var(--black);
            opacity: .05;
        }

        &::after {
            background: radial-gradient(38% 50% at 48% 48%,transparent 0,#000 105%);
        }
    }

    &__title {
        margin-bottom: ac(35px, 25px);
        font-size: ac(26px, 20px);
        line-height: ac(32px, 26px);
        font-weight: 400;
    }

    &__subtitle {
        margin-top: ac(25px, 20px);
        font-size: ac(22px, 18px);
        line-height: ac(26px, 22px);
        font-weight: 300;
        text-align: right;
    }

    &__descr {
        max-width: 50%;
    
        p {
            font-size: ac(18px, 16px);
            line-height: ac(26px, 24px);

            &:not(:last-child) {
                margin-bottom: ac(20px, 10px);
            }
        }
    }

    @mixin mob-lg {
        &__bg {
            &::after {
                background: radial-gradient(45% 48% at 48% 46%,transparent 0,#000 100%);
            }
        }
    }

    @mixin media 1023 {
        &__bg {
            right: 0;
            top: 0;
            width: 100%;

            img {
                object-position: center;
            }
        }

        &__wrapper {
            padding-top: 400px;
        }

        &__descr {
            max-width: 100%;
            margin: 0 auto;
        }
    }
}