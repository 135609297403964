.testimonials {
    position: relative;
    margin-top: ac(13px, 102px);
    padding-bottom: ac(182px, 38px);

    &__ancor {
        position: absolute;
        top: ac(-120px, -80px);
    }

    &--sector {
        margin-top: ac(200px, 85px);
        padding-bottom: ac(128px, 38px);
    }

    &--about {
        margin-top: ac(215px, 85px);

        .testimonials__wrapper {
            margin-top: ac(40px, 28px);

            &:nth-child(2) {
                margin-top:  ac(44px, 28px);
            }
        }
    }

    &__decor {
        bottom: -420px;
        height: 1250px;
        left: -429px;
        position: absolute;
        width: 1250px;
        z-index: -1;

        img {
            object-fit: contain;
            opacity: .5;

            &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                display: none !important;
            }
        }
    }

    .container {
        max-width: 1030px;
    }

    &__wrapper {
        margin-top: ac(46px, 28px);

        &:nth-child(2) {
            margin-top:  ac(63px, 28px);
        }
        
        &>div {
            display: flex;
        }
    }

    .js-marquee {
        display: flex;
    }

    &__card {
        display: flex;
        max-width: ac(439px, 335px);
        flex: 0 0 auto; 
        margin-right: ac(120px, 30px);
    }

    &__logo {
        flex: 0 0 auto;
        margin-right: 20px;
        width: ac(80px, 70px);
        height: ac(80px, 70px);
        border-radius: 100%;
        background-color: var(--white);
        overflow: hidden;
        padding: 10px;

        img {
            object-fit: contain;
        }
    }

    &__col {
        width: 100%;
        padding-top: ac(15px, 10px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__name {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
    }

    &__position {
        line-height: 22px;
        font-size: 14px;
        color: var(--white);
    }

    &__descr {
        width: 100%;
        margin-top: ac(25px, 10px);
        font-size: ac(16px, 14px);
        line-height: ac(26px, 24px);
        max-height: ac(103px, 99px);
        padding-right: 5px;
        overflow: auto;

        scrollbar-width: thin;
        scrollbar-color: rgba(255, 255, 255, .3) var(--white);

        &::-webkit-scrollbar {
            width: 6px;
            background-color: rgba(255, 255, 255, .3);
            border-radius: 7px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--white);
            border-radius: 7px;
        }
    }

    @mixin tab {
        h2 {
            text-align: center;
        }

        &__decor {
            bottom: -175px;
            height: 150%;
            left: -311px;
            width: 200%;

            img {
                object-fit: contain;
                object-position: left bottom;
            }
        }
    }

}