.benefits {
    position: relative;
    z-index: 2;
    background-color: var(--onyx);

    &__wrapper {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
    }

    &__card {
        position: relative;
        padding: ac(78px, 15px, 658, 1440) ac(79px, 15px, 658, 1440);
        width: calc(100% / 3);
        min-height: 342px;
        border: 1px solid var(--dune);
        z-index: 1;
        cursor: pointer;

        &.active, &:hover {
            .benefits__bg {
                &::before {
                    opacity: 0;
                }
            }

            .benefits__descr {
                margin-top: 25px;
                opacity: 1;
            }

            .benefits__title {
                .arrow {
                    transform: translateY(-2px) rotate(-45deg);
                }
            }
        }

        &.red {
            .benefits__bg {
                img {
                    &:nth-child(1) {
                        opacity: .6;
                    }
                }
            }
        }

        &.blue {
            .benefits__bg {
                img {
                    &:nth-child(2) {
                        opacity: .6;
                    }
                }
            }
        }

        &.yellow {
            .benefits__bg {
                img {
                    &:nth-child(3) {
                        opacity: .6;
                    }
                }
            }
        }

    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--onyx);
            transition: .3s ease;
        }

        img {
            position: absolute;
            top: 0;
            opacity: 0;
            z-index: -1;
        }
    }

    &__title {
        font-size: ac(30px, 26px);
        line-height: ac(40px, 36px);
        font-weight: 500;
        max-width: 12ch;
        .arrow {
            width: 14px;
            height: 14px;
            margin-left: 11px;
        }
    }

    &__descr {
        line-height: 26px;
        opacity: 0;
        transition: .3s ease;

        width: calc(100% + ac(26px, 15px));
        max-height: ac(104px, 130px, 1024, 1440);
        padding-right: 15px;

        .simplebar-vertical {
            width: 6px;
            background-color: rgba(255, 255, 255, .3);
            border-radius: 7px;
        }

        .simplebar-scrollbar::before {
            opacity: 1;
            background-color: var(--white);
            left: 0;
            right: 0;
        }

        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            top: 0;
            bottom: 0;
        }
    }

    @mixin mob-xl {
        &__wrapper {
            flex-direction: column;
        }

        &__card {
            width: 100%;
            padding: 30px 20px;
        }
    }
}