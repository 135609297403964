@import "https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500&display=swap";

:root {
  --black: #000000;
  --onyx: #0F0F0F;
  --white: #ffffff;
  --grey: #54534D;
  --smokey: #717171;
  --davy: #585751;
  --dune: #393834;
  --font-main: 'Lexend Deca', sans-serif;
}
