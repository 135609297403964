.solutions {
    position: relative;
    padding-top: ac(138px, 58px);

    &__slider-bg {
        position: absolute;
        top: -92px;
        left: 0;
        width: 100%;
        height: 99%;
        z-index: -1;
        overflow: hidden !important;
        
        picture {
            img {
                opacity: .5;
            }
        }

        .swiper-slide {
            opacity: 0 !important;

            &-active {
                opacity: 1 !important;
            }
        }
    }

    &__shadow, &__dots {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 2px;
        z-index: 2;
    }

    &__dots {
        img {
            object-fit: contain;
        }
    }

    &__title-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__slider {
        margin-top: ac(361px, 250px, 1024, 1440);
    }

    &__slide {
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-child(1) {
            .solutions__decor {
                left: -50px;
                top: -50px;
            }
        }

        &:nth-child(2) {
            .solutions__decor {
                height: 144%;
                left: ac(-87px, -65px, 1024, 1440);
                top: ac(-105px, -80px, 1024, 1440);
                width: 144%;
            }
        }

        &:nth-child(3) {
            .solutions__decor {
                height: 142%;
                width: 142%;
                left: -80px;
                top: -70px;
            }
        }

        &:hover {
            .solutions__name {
                .arrow {
                    transform: rotate(-45deg);
                }
            }

            .solutions__descr {
                opacity: 1;
                transition: .3s .3s ease;
            }
        }
    }

    &__circle {
        position: relative;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 100%;
        transition: .3s .3s ease !important;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--black);
            border-radius: 100%;
            transition: .3s ease;
        }
    }

    &__decor {
        position: absolute;
        height: 140%;
        width: 140%;
        z-index: -1;

        img {
            object-fit: contain;
        }
    }

    &__name {
        max-width: 10ch;
        margin-left: ac(-32px, -29px);
        position: relative;
        font-size: ac(30px, 24px);
        line-height: ac(40px, 30px);
        font-weight: 500;
        text-align: right;
        transition: .3s ease;

        .arrow {
            position: absolute;
            width: ac(14px, 16px);
            height: ac(14px, 16px);
            right: ac(-34px, -30px);
            bottom: ac(10px, 7px);
            transition: .3s ease;
        }
    }

    &__subheading {
        font-size: ac(18px, 16px);
        margin-left: ac(-36px, -48px, 320, 1440);
        color: var(--white);
        text-align: center;
        z-index: 1;
        transition: .3s ease;
    }

    &__descr {
        height: 100%;
        max-height: 84px;
        text-align: center;
        z-index: 1;
        transition: .3s ease;
        opacity: 0;
        @mixin max-line-length 3;
    }

    &__pagination {
        bottom: 0;

        .swiper-pagination-bullet {
            position: relative;
            margin: 0 18px !important;
            background: var(--white);
            transition: .3s ease;
            opacity: 1;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                width: 24px;
                height: 24px;
                border-radius: 100%;
                border: 1px solid var(--white);
                transition: .3s ease;
                
            }

            &-active, &:hover {
                &::before {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
    }

    @media (min-width: 1024px) {
        &__pagination {
            display: none;
        }

        &__slide {
            &:nth-child(1) {
                width: ac(381px, 320px, 1024, 1440);
                height: ac(381px, 320px, 1024, 1440);
                margin-top: ac(-296px, -200px, 1024, 1440);
                margin-left: ac(111px, 10px, 1024, 1440);
            }
    
            &:nth-child(2) {
                width: ac(412px, 340px, 1024, 1440);
                height: ac(412px, 340px, 1024, 1440);
                margin-left: 141px;
                margin-top: 12px;
            }
    
            &:nth-child(3) {
                width: ac(390px, 330px, 1024, 1440);
                height: ac(390px, 330px, 1024, 1440);
                margin-top: ac(-455px, -340px, 1024, 1440);
                margin-left: -157px;
            }
    
            &:hover {
                .solutions__circle {
                    width: 100%;
                    height: 100%;
                    transition: .3s ease !important;
                }
            }
        }
    
        &__circle {
            width: ac(350px, 300px, 1024, 1440);
            height: ac(350px, 300px, 1024, 1440);
        }
    }

    @mixin tab {
        padding-bottom: 84px;

        &__slider {
            margin-top: ac(300px, 137px);
        }

        &__btn {
            margin-top: ac(311px, 28px);
        }

        &__slide {
            height: ac(311px, 270px, 900, 1023);
           
            &:nth-child(1) {
                .solutions__decor {
                    left: -15%;
                    top: -15%;
                }
            }

            &:nth-child(2) {
                .solutions__decor {
                    left: -25%;
                    top: -25%;
                }
            }

            &:nth-child(3) {
                .solutions__decor {
                    left: -20%;
                    top: -20%;
                }
            }
        }

        &__circle {
            width: 100%;
            height: 100%;
            padding: 44px;
        }
        
    }

    @mixin tab-md {
        &__slide {
            height: ac(417px, 251px, 568, 900);
        }
    }

    @mixin mob-lg {
        &__slider-bg  {
            height: 65%;
            top: 40px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-shadow: inset 0 0 75px 75px #000;
                z-index: 1;
            }
        }

        &__shadow {
            display: none;
        }

        &__slide {
            height: ac(527px, 335px, 375, 568);
        }

        &__circle {
            width: 78%;
            height: 78%;
        }
    }

    @mixin mob-sm {
        &__slide {
            height: ac(334px, 280px, 320, 374);
        }

        &__circle {
            width: 100%;
            height: 100%;
        }
    }
}
