.team {
    position: relative;
    margin-top: ac(90px, 75px);
    padding-top: ac(140px, 50px);
    padding-bottom: ac(140px, 50px);

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        
        img {
            box-shadow: inset 0 0 50px 50px var(--black);
        }

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &::before {
            background: var(--black);
            opacity: .7;
        }

        &::after {
            background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        }
    }

    &__dots {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 569px;
        height: 569px;
        transform: translate(-50%, -50%);
        z-index: -1;

        img {
            object-fit: contain;
        }
    }

    &__title {
        position: relative;
        margin-bottom: ac(40px, 15px);
        margin-left: ac(-108px, -0px);
        z-index: 2;
    }

    &__wrapper {
        padding: 0 ac(217px, 0px, 768, 1440);
        display: flex;
        position: relative;
    }

    &__slider-box {
        width: 100%;
        flex: 0 0 auto;

        &:nth-child(1) {
            max-width: ac(521px, 421px, 1024, 1440);
            margin-right: ac(132px, 50px, 1024, 1440);
            z-index: 2;
        }

        &:nth-child(2) {
            margin-top: 44px;
            max-width: ac(353px, 280px, 320, 568);
        }
    }

    .swiper-pagination {
        bottom: -50px;

        .swiper-pagination-bullet {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 24px;
            height: 24px;
            transition: .3s ease;
            opacity: 1;
            background: transparent;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                width: 100%;
                height: 100%;
                border-radius: 100%;
                border: 1px solid var(--white);
                transition: .3s ease;
            }

            &::after {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: var(--white);
                transition: .3s ease;
            }

            &-active, &:hover {
                &::before {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
    }

    .swiper {
        .swiper-slide {
            opacity: 0 !important;

            &-active {
                opacity: 1 !important;
            }
        }
    }

    &__text-slider {
        .swiper-slide {
            .team__text {
                transform: translateX(-50%);
                opacity: 0;
            }

            &-active {
                .team__text {
                    transition: .5s ease;
                    opacity: 1;
                    transform: translateX(0%);
                }
            }
        }
    }

    &__photo-slider {
        .swiper-slide {
            .team__img {
                transform: translateX(50%);
                opacity: 0;
                
            }

            &-active {
                .team__img {
                    transition: .5s ease;
                    opacity: 1;
                    transform: translateX(0%);

                    &::before {
                        transform: translate(-50%, -50%) rotate(0deg) scale(1);
                    }
                }
            }
        }
    }

    &__name {
        margin-bottom: ac(15px,12px);
        font-size: ac(26px, 20px);
        line-height: ac(32px, 26px);
        font-weight: 400;
    }

    &__position {
        margin-bottom: ac(11px, 8px);
        font-size: ac(22px, 18px);
        line-height: ac(26px, 22px);
        font-weight: 300;
    }

    &__descr {
        margin-bottom: ac(20px, 15px);
        letter-spacing: .17px;

        width: calc(100% + ac(26px, 15px));
        max-height: ac(196px, 160px);
        padding-right: ac(26px, 15px);

        .simplebar-vertical {
            width: 6px;
            background-color: rgba(255, 255, 255, .3);
            border-radius: 7px;
        }

        .simplebar-scrollbar::before {
            opacity: 1;
            background-color: var(--white);
            left: 0;
            right: 0;
        }

        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            top: 0;
            bottom: 0;
        }
    }

    &__soc-box {
        display: flex;
        margin-bottom: ac(40px, 20px);
    }

    &__soc {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        border: 1px solid var(--grey);
        transition: .3s ease;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &:hover {
            background-color: var(--white);
            border-color: var(--white);
        }

        &--tel {
            .team__icon {
                width: 16px;
                height: 17px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M1.885 1.01a1.745 1.745 0 0 1 2.61.163L6.29 3.48c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.679.679 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.635 18.635 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885 1.01Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }

            &:hover {
                .team__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='M1.885 1.01a1.745 1.745 0 0 1 2.61.163L6.29 3.48c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.679.679 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.635 18.635 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885 1.01Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                }
            }
        }

        &--in {
            .team__icon {
                width: 16px;
                height: 17px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23' fill='none'%3E%3Cpath fill='%23fff' d='M4.98 2.5A2.49 2.49 0 0 1 2.5 5 2.49 2.49 0 0 1 .02 2.5C.02 1.12 1.13 0 2.5 0a2.49 2.49 0 0 1 2.48 2.5ZM5 7H0v16h5V7Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23H24V12.869c0-7.88-8.922-7.593-11.018-3.714V7Z'/%3E%3C/svg%3E");
            }

            &:hover {
                .team__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='23' fill='none'%3E%3Cpath fill='%23000' d='M4.98 2.5A2.49 2.49 0 0 1 2.5 5 2.49 2.49 0 0 1 .02 2.5C.02 1.12 1.13 0 2.5 0a2.49 2.49 0 0 1 2.48 2.5ZM5 7H0v16h5V7Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23H24V12.869c0-7.88-8.922-7.593-11.018-3.714V7Z'/%3E%3C/svg%3E");
                }
            }
        }

        &--mail {
            .team__icon {
                width: 16px;
                height: 17px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='13' fill='none'%3E%3Cpath fill='%23fff' d='M.05 2.055A2 2 0 0 1 2 .5h12a2 2 0 0 1 1.95 1.555L8 6.914.05 2.055ZM0 3.197v7.104l5.803-3.558L0 3.197ZM6.761 7.33l-6.57 4.027A2 2 0 0 0 2 12.5h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 8.086l-1.239-.757v.001Zm3.436-.586L16 10.301V3.197l-5.803 3.546v.001Z'/%3E%3C/svg%3E");
            }

            &:hover {
                background-color: var(--white);
                border-color: var(--white);

                .team__icon {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='13' fill='none'%3E%3Cpath fill='%23000' d='M.05 2.055A2 2 0 0 1 2 .5h12a2 2 0 0 1 1.95 1.555L8 6.914.05 2.055ZM0 3.197v7.104l5.803-3.558L0 3.197ZM6.761 7.33l-6.57 4.027A2 2 0 0 0 2 12.5h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 8.086l-1.239-.757v.001Zm3.436-.586L16 10.301V3.197l-5.803 3.546v.001Z'/%3E%3C/svg%3E");
                }
            }
        }
    }

    &__icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: .3s ease;
    }

    &__img {
        position: relative;
        height: ac(353px, 280px, 320, 568);

        img {
            border-radius: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background: url('../images/team-gradient-main.svg') center / contain no-repeat;
            z-index: -1;
            transform: translate(-50%, -50%) rotate(-280deg) scale(2);
            width: 145%;
            height: 145%;
            transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }

    @mixin tab {
        &__wrapper {
            flex-direction: column-reverse;
        }

        &__slider-box {
            &:nth-child(1) {
                max-width: unset;
                margin-right: 0;
            }
    
            &:nth-child(2) {
                margin: 0 auto ac(0px, 35px, 320, 568);
            }
        }
    }

    @mixin mob-lg {
        &__title {
            text-align: center;
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: center;

            &>* {
                text-align: center;
            }
        }
    }

}