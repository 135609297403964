.history {
    position: relative;
    padding-top: ac(140px, 50px);
    padding-bottom: ac(140px, 50px);
    margin-top: 50px;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        
        img {
            box-shadow: inset 0 0 50px 50px var(--black);
        }

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &::before {
            background: var(--black);
            opacity: .7;
        }

        &::after {
            background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        }
    }

    &__dots {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 569px;
        height: 569px;
        transform: translate(-50%, -50%);
        z-index: -1;

        img {
            object-fit: contain;
        }
    }

    &__title {
        text-align: center;
    }

    &__slider-box {
        margin-top: ac(71px, 45px);
        max-width: calc(100% - ac(40px, 0px, 1024, 1440));
    }

    &__slide {
        &:last-child {
            .history__card {
                &::after {
                    display: none;
                }
            }
        }
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            top: calc(ac(150px, 100px) / 2);
            left: ac(150px, 100px);
            background-color: var(--smokey);
        }

        &:hover {
            .history__year {
                border-color: var(--white);
                background-color: var(--onyx);

                &::before {
                    background-color: var(--white);
                }
            }
        }
    }

    &__year {
        position: relative;
        margin-bottom: ac(111px, 65px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: ac(150px, 100px);
        height: ac(150px, 100px);
        border-radius: 100%;
        border: 1px solid var(--smokey);
        background-color: var(--black);

        span {
            font-size: 14px;
            transform-origin: 50% 50%;
        }

        &, span, &::before {
            transition: .3s ease;
        }

        &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: ac(94px, 55px);
            bottom: -1px;
            transform: translateY(100%);
            background-color: var(--smokey);
        }

    }

    &__name {
        font-size: ac(26px, 20px);
        line-height: ac(36px, 30px);
        font-weight: 400;
        margin-bottom: 12px;
    }

    &__descr {
        width: 100%;
        max-height: ac(196px, 160px);

        .simplebar-vertical {
            width: 6px;
            background-color: rgba(255, 255, 255, .3);
            border-radius: 7px;
        }

        .simplebar-scrollbar::before {
            opacity: 1;
            background-color: var(--white);
            left: 0;
            right: 0;
        }

        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            top: 0;
            bottom: 0;
        }
    }

    &__text {
        letter-spacing: .17px;
        padding-right: 15px;
    }

    .swiper-pagination {
        bottom: ac(80px, -10px);

        .swiper-pagination-bullet {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 24px;
            height: 24px;
            transition: .3s ease;
            opacity: 1;
            background: transparent;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                width: 100%;
                height: 100%;
                border-radius: 100%;
                border: 1px solid var(--white);
                transition: .3s ease;
            }

            &::after {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: var(--white);
                transition: .3s ease;
            }

            &-active, &:hover {
                &::before {
                    transform: translate(-50%, -50%) scale(1);
                }
            }
        }
    }
}