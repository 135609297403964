.searches {
    margin-top: 70px;
    background-color: var(--onyx);

    &__wrapper {
        position: relative;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
    }

    &__title-box {
        position: relative;
        flex: 0 0 auto;
        width: 100%;
        padding: ac(29px, 25px, 1024, 1440) ac(80px, 40px, 1024, 1440);
        max-width: ac(513px, 320px, 1024, 1440);
        border: 1px solid var(--dune);
        z-index: 2;
        background-color: var(--onyx);
    }

    
    &__contact-btn {
        position: absolute;
        bottom: -41px;
        right: -59px;
        
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
     
        width: ac(150px, 100px);
        height: ac(150px, 100px);
        border-radius: 100%;
        background-color: var(--black);
        border: 1px solid var(--davy);
        transition: .3s ease;

        .text {
            margin-top: -4px;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: ac(14px, 12px);
            font-weight: 500;
            transition: .3s ease;
        }

        .arrow {
            width: 21px;
            height: 21px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.707' height='21.707'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='M8.707 20.707h13M20.707 9.707v12M.707.707l20 20'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:hover {
            background-color: var(--white);

            .text {
                color: var(--black);
            }

            .arrow {
                transform: rotate(-45deg);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.707' height='21.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M8.707 20.707h13M20.707 9.707v12M.707.707l20 20'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    &__slider-box {
        display: flex;
        width: calc(100% - ac(513px, 320px, 1024, 1440) - ac(178px, 70px, 1024, 1440));
    }

    &__card {
        height: 100%;
        padding: 30px 19px ac(110px, 80px, 1024, 1440);
        min-height: ac(340px, 280px);
        background-color: var(--black);
        border: 1px solid var(--dune);
        transition: .3s ease;

        &:hover {
            background-color: var(--onyx);
            border-color: var(--white);
        }
    }

    &__name {
        font-size: ac(26px, 20px);
        line-height: ac(32px, 26px);
        font-weight: 400;
    }

    &__location {
        margin-top: ac(15px,12px);
        font-size: ac(22px, 18px);
        line-height: ac(26px, 22px);
        font-weight: 300;
    }

    &__descr {
        margin-top: ac(15px, 10px);
        max-height: ac(104px, 88px);
        @mixin max-line-length 4;

        &>*, & {
            font-size: ac(20px, 16px);
            line-height: ac(26px, 22px);
            font-weight: 300;
        }
    }

    @media (min-width: 1441px) {
        &__wrapper {
            &::before {
                content: '';
                position: absolute;
                width: calc(100% + 50vw);
                height: 100%;
                left: 1px;
                top: 0;
                transform: translateX(-100%);
                background-color: var(--onyx);
                z-index: 2;
            }
        }
    }

    @mixin tab {
        &__wrapper {
            flex-direction: column;
        }

        &__card {
            padding-bottom: 30px;
        }

        &__title-box {
            max-width: unset;
            display: flex;
            align-items: center;
            padding: 20px;
        }

        &__slider-box {
            width: calc(100% - ac(178px, 30px));
        }

        &__contact-btn {
            position: relative;
            bottom: 0;
            right: 0;
            margin-left: auto;
        }
    }
}