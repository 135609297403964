.contact-us {
    position: relative;
    margin-top: 56px;
    z-index: 2;

    &__form, &__input-box  {
        display: flex;
        flex-direction: column;
    }

    &__form {
        margin: 0 auto;
        width: 100%;
        max-width: 630px;
        align-items: center;
    }

    &__input-box {
        width: 100%;
        margin-bottom: ac(34px, 20px);
    }

    &__label {
        margin-bottom: ac(5px, 0px);
        font-size: 14px;
        font-weight: 300;
    }

    &__input {
        padding-bottom: 11px;
        background-color: transparent;
        border-radius: 0;
        transition: .3s ease;
        @mixin transparent-bg-input;

        &,&::placeholder {
            color: var(--white);
            font-size: 18px;
            line-height: ac(40px, 36px);
            font-weight: 300;
            font-family: var(--font-main);
        }

        &::placeholder {
            opacity: 0.5;
            transition: .1s ease;
        }

        &:focus {
            &::placeholder {
                opacity: 0;
            }
        }

        &:hover, &:focus {
            &+.contact-us__line {
                &::before {
                    width: 100%;
                    opacity: 1;
                }
            }
        }

        &:valid {
            &+.contact-us__line {
                &::after {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }

    &__line {
        position: relative;
        height: 1px;
        background-color: rgba(255, 255, 255, .5);
        transition: .3s ease;

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 1px;
            opacity: 0;
            transition: .5s ease;
        }

        &::after {
            background: rgba(88,246,156,1);
        }

        &::before {
            background: linear-gradient(90deg, rgba(88,246,156,1) 0%, rgba(251,190,48,1) 35%, rgba(166,77,44,1) 100%);
        }
    }

    textarea {
        resize: none;

        scrollbar-width: thin;
        scrollbar-color: rgba(255,255,255, .2) var(--white);

        &::-webkit-scrollbar {
            width: 8px;
            background-color: rgba(255,255,255, .2);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--white);
        }
    }

    &__btn {
        margin-left: auto;
        margin-top: 15px;
    }
}