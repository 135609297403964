.our {
    position: relative;
    z-index: 2;

    &__wrapper {
        display: flex;
        justify-content: space-around;
    }

    &__card {
        display: flex;
        justify-content: center;
        align-items: center;

        width: ac(452px, 340px, 768, 1440);
        height: ac(452px, 340px, 768, 1440);
      

        &:hover {
            .our__circle {
                width: 100%;
                height: 100%;
                transition: .3s ease !important;
            }

            .our__descr {
                opacity: 1;
                transition: .3s .3s ease;
            }

            .our__name {
                .arrow {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__decor {
        left: -70px;
        top: -70px;
        position: absolute;
        height: 140%;
        width: 140%;
        z-index: -1;

        img {
            object-fit: contain;
        }
    }

    &__circle {
        width: ac(397px, 300px, 768, 1440);
        height: ac(397px, 300px, 768, 1440);
        position: relative;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 100%;
        transition: .3s .3s ease !important;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--black);
            border-radius: 100%;
            transition: .3s ease;
        }
    }

    &__name {
        margin-left: ac(-42px, -39px);
        position: relative;
        font-size: ac(50px, 24px);
        line-height: ac(60px, 30px);
        font-weight: 500;
        transition: .3s ease;

        .arrow {
            position: absolute;
            width: ac(23px, 16px);
            height: ac(23px, 16px);
            right: ac(-47px, -30px);
            bottom: ac(12px, 7px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' fill='none'%3E%3Cpath fill='%23fff' d='M17.66 19.872 0 2.212 2.212 0l17.66 17.66V7.362H23V23H7.361v-3.128h10.3Z'/%3E%3C/svg%3E");
        }
    }

    &__descr {
        height: 100%;
        max-height: 84px;
        text-align: center;
        z-index: 1;
        transition: .3s ease;
        opacity: 0;
        @mixin max-line-length 3;
    }

    @mixin tab-sm {
        &__wrapper {
            align-items: center;
            flex-direction: column;
        }

        &__card {
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
}