.header {
    position: absolute;
    width: 100%;
    top: 66px;
    left: 0;
    z-index: 100;

    &__wrap, &__nav {
        display: flex;
        align-items: center;
    }

    &__nav {
        width: 100%;
    }

    &__link {
        text-transform: uppercase;
        letter-spacing: 0.025em;
        margin-right: ac(50px, 20px, 1024, 1440);
        color: var(--white);
        font-weight: 400;
        transition: .3s ease;
        background: -moz-linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
        background: linear-gradient(90deg, rgba(166,77,44,1) 0%, rgba(251,190,48,1) 35%, rgba(88,246,156,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;

        &--alt {
            margin-left: auto;
        }

        .arrow {
            display: none;
        }

        &:hover {
            color: rgba(0, 0, 0, 0);
        }
    }

    &__logo {
        position: fixed;
        left: 50%;
        transform: translate(-50%);
        width: 112px;
        height: 112px;
        transition: .3s ease;

        &>img {
            object-fit: contain;
            transition: .3s ease;

            &:nth-child(2) {
                position: absolute;
                top: 0;
                opacity: 0;
            }
        }

        &:hover {
            .header__logo-gradient {
                transform: rotate(-90deg) scale(1.1);
            }

            &>img {
                &:nth-child(1) {
                    opacity: 0;
                }
                
                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }
    }

    &__logo-gradient {
        position: absolute;
        top: -22px;
        left: -22px;
        width: 160px;
        height: 160px;
        z-index: -1;
        transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &>img {
            object-fit: contain;
            transition: .3s ease;
        }
    }

    &__burger, &__arrow {
        display: none;
    }

    &__layout {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        background-color: var(--white);
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease;
        z-index: -1;
        cursor: pointer;
    }

    &__bottom {
        flex-direction: column;
        margin-top: auto;
    }

    &__title {
        font-size: 18px;
        line-height: 28px;
    }

    &__mail {
        margin-top: ac(8px, 5px);
        font-size: ac(50px, 24px);
        line-height: ac(62px, 34px);
        font-weight: 500;
    }

    &__copy-box {
        width: 100%;
        margin-top: 31px;
        padding-left: 6px;

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__copy {
        margin: 0 ac(20px, 10px, 320, 375);
        font-size: 14px;
        line-height: 33px;
        color: var(--smokey);
        transition: .3s ease;
    }

    @mixin tab {
        position: fixed;
        top: 0;

        &__wrap {
            height: 96px;
        }

        &__burger, &__arrow {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            border: 1px solid var(--grey);
            border-radius: 100%;
            background-color: var(--black);
            transition: .3s ease;
        }

        &__arrow {
            position: absolute;
            right: 20px;
           
            .arrow {
                width: 14px;
                height: 14px;
                transition: .3s ease;
            }

            &:hover {
                transform: rotate(-45deg);

                background-color: var(--white);

                .arrow {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.707' height='10.707'%3E%3Cg fill='none' stroke='%23000' stroke-width='2'%3E%3Cpath d='M2.707 9.707h8M9.707 2.707v8M.707.707l9 9'/%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }

        &__burger {
            z-index: 10;
            cursor: pointer;

            span {
                width: 10px;
                height: 2px;
                background-color: var(--white);
                transition: .3s ease;

                &:nth-child(1) {
                    margin-left: 6px;
                }

                &:nth-child(2) {
                    width: 16px;
                    margin: 3px 0;
                }

                &:nth-child(3) {
                    margin-right: 6px;
                }
            }

            &:hover {
                background-color: var(--white);

                span {
                    background-color: var(--black);
                }
            }
        }

        &__logo {
            width: 65px;
            height: 65px;
        }

        &__logo-gradient {
            top: -14px;
            left: -14px;
            width: 92px;
            height: 92px;
        }

        &__nav {
            position: absolute;
            left: 0;
            top: 0;
            flex-direction: column;
            align-items: center;
            background-color: var(--black);
            height: 100vh;
            height: calc((var(--vh, 1vh) * 100) - 0px);
            transform: translateX(-100%);
            padding: 130px 26px 23px 20px;
            transition: .3s ease;
        }

        &__link {
            width: 100%;
            max-width: 500px;
            display: inline-flex;
            align-items: center;
            font-weight: 400;
            font-size: 30px;
            line-height: 38px;
            text-transform: none;
            margin-right: 0;
            letter-spacing: 0;

            &:not(:first-child) {
                margin-top: 24px;
            }

            .arrow {
                height: 22px;
                width: 22px;
                margin-left: auto;
                margin-bottom: -3px;
                margin-right: -3px;
                display: inline-flex;
                background-position: right bottom;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23fff' d='M10.586 12.471.862 2.748 2.748.862l9.723 9.724V4.47h2.667v10.667H4.472v-2.667h6.114Z'/%3E%3C/svg%3E");
            }

            &:hover {
                .arrow {
              
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='url(%23a)' d='M10.586 12.471.862 2.748 2.748.862l9.723 9.724V4.47h2.667v10.667H4.472v-2.667h6.114Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='8' x2='23' y1='1.5' y2='7.5' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.016' stop-color='%23A64D2C' stop-opacity='.894'/%3E%3Cstop offset='.24' stop-color='%23FBBE30'/%3E%3Cstop offset='.464' stop-color='%2358F69C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
                    transform: rotate(-45deg);
                }
            }
        }

        &__link--alt {
            margin-left: 0;
        }

        &.active {
            .header__logo {
                left: 28px;
                transform: translateX(0);
            }

            .header__nav {
                transform: translateX(0%);
            }

            .header__layout {
				opacity: 0.1;
				visibility: visible;
			}

            .header__burger {
                margin-left: calc(100% - 44px);

                span {
                    &:nth-child(1) {
                        width: 0;
                    }
    
                    &:nth-child(2) {
                        width: 17px;
                        transform: rotate(45deg);
                    }
    
                    &:nth-child(3) {
                        transform: translate(3px, -5px) rotate(-45deg);
                        width: 17px;
                    }
                }
            }
        }
    }

    @mixin mob-sm {
        &__mail {
            font-size: 22px;
        }
    }

    
    @media (min-height: 1000px) and (max-width: 1023px) {
        &__nav {
            max-height: 700px;
        }
    }

    @media (max-height: 650px) and (max-width: 1023px) {
        &__nav {
            padding-top: 95px;
        }

        &__link {
            &:not(:first-child) {
                margin-top: 14px;
            }
        }
    }
}